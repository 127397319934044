<template>
    <div class="scratch-container">
        <div class="area">

            <MainArea ref="main"></MainArea>

            <Bottom
                @selectSprite="selectSprite"
                @deleteSprite="deleteSprite">
            </Bottom>

        </div>

        <div id="blockly-code-generator" class="blockly-code-generator" style="display: none"></div>

        <Loading />

    </div>
</template>

<script>
    import MainArea from '@/views/pages/ide/scratch/children/MainArea';
    import Bottom from '@/views/pages/ide/scratch/children/Bottom';
    import Loading from '@/views/components/mask/Loading';

    import Ide from '@/libs/ide/ide.js';
    import CoreData from '@/libs/runtime/coreData.js';
    import qs from 'qs'
    import cos from '@/utils/cos.js'
    import "@/libs/index.js";
    import { mapGetters } from 'vuex'

    // common
    import { getCourseInfo, getSectionInfo } from '@/utils/common'
    // web/admin同名，但需要区分的接口
    import { sendReleaseMsg, getClassroomData, saveMySectionRes, saveQuestionBlocks } from '@/api/admin'
    // web
    import { genResId, createProject, saveProjectCover } from '@/api/web'
    // admin
    import { getAdminSectionData, saveAdminSectionRes } from '@/api/admin'
    // admin_exam
    import { getQuestion4Admin  } from '@/api/admin_exam'

    export default {
        name: 'scratch',
        data: function() {
            return {
                curEnv: '',

                resId: '',
                userResId: '',

                // 定时保存
                interval: null,
            }
        },
        // // 不知道为啥没有调用成功？
        // // 但是暂时离开页面有提示，先用着，后面排查
        // beforeRouteLeave: function(to, from , next){
        //     let value = window.confirm("确定要离开当前页面吗？");
        //     if (value == true) {
        //         // 确定
        //         this.clearTimer()
        //         next()
        //     } else {
        //         // 取消
        //         next(false)
        //     }
        // },
        computed: {
            ...mapGetters([
                'ide',
                'dataAreaData',
                'actorAreaData',
                'curActor',
                'pid',
                'cid',
                'sid',
                'current',
            ]),
        },
        created() {
        },
        mounted() {
            let that = this;

            // 加载userInfo
            this.loadUserInfo();

            this.clearTimer();
            this.interval = setInterval(that.autoSave, 60 * 1000); //暂时间隔60秒
        },
        unmounted() {
            this.clearTimer();
        },
        activated() {
        },
        methods: {
            getCourseInfo,
            getSectionInfo,
            async loadUserInfo() {
                await this.$store.dispatch('user/getUserInfo');
            },
            clearTimer() {
                if (this.interval) {
                    clearInterval(this.interval);
                    this.interval = null;
                }
            },
            loadPage(cid, sid, pid, curEnv = null) {
                if (curEnv) {
                    this.curEnv = curEnv
                }

                if (this.curEnv == 'classroom') {
                    this.loadClassroomData(cid, sid)

                } else if (this.curEnv == 'editor') {
                    this.loadEditorData(cid, sid)

                } else if (this.curEnv == 'exercise') {
                    this.loadProjectData(pid)

                } else if (this.curEnv == 'ide') {
                    this.loadProjectData(pid)

                } else if (this.curEnv == 'question') {
                    this.loadQuestionData(pid)
                }
            },
            async loadClassroomData(cid, sid) {
                this.$store.commit('web/SetCourseId', cid)
                this.$store.commit('web/SetSectionId', sid)

                let toolbox = ''
                let res = await getClassroomData({ cid, sid })
                if (res && res.data) {
                    this.resId = res.data.resId
                    this.userResId = res.data.userResId
                    toolbox = res.data.toolbox
                }

                // 加载上课信息
                this.getCourseInfo(cid)

                // 加载小节信息
                this.getSectionInfo(sid)

                // 展示课程目标示例
                this.$store.commit('web/SetCurrent', 'demo')

                // 加载上课数据
                this.$store.dispatch('web/LoadClassroomData', {
                    resId: this.resId,
                    userResId: this.userResId,
                    toolbox: toolbox,
                });
            },
            async loadEditorData(cid, sid) {
                this.$store.commit('web/SetCourseId', cid)
                this.$store.commit('web/SetSectionId', sid)

                let res = await getAdminSectionData({ cid, sid })
                if (res && res.data) {
                    this.resId = res.data.resId
                    this.$store.commit('web/SetProjectId', this.resId)
                }

                // 展示用户作品
                this.$store.commit('web/SetCurrent', 'demo')

                // 加载作品数据
                this.$store.dispatch('web/LoadEditorData', this.resId)

                // 加载小节信息
                this.getSectionInfo(sid)

                // Editor模式下，打开demo code mask
                this.$store.commit('web/SetIsShowDemoCode', true);
            },
            loadProjectData(pid) {
                this.$store.commit('web/SetProjectId', pid)

                // 加载Project信息
                this.$store.dispatch('web/GetProjectInfo', pid)

                // 展示用户作品
                this.$store.commit('web/SetCurrent', 'user')

                // 加载作品数据
                this.$store.dispatch('web/LoadProjectData', pid)
            },
            async loadQuestionData(pid) {
                this.$store.commit('web/SetProjectId', pid)

                let toolbox = ''
                let res = await getQuestion4Admin({ qid: pid })
                if (res && res.data) {
                    this.resId = res.data.question.qid
                    // toolbox = res.data.toolbox
                }

                // 展示课程目标示例
                this.$store.commit('web/SetCurrent', 'demo')

                // 加载上课数据
                this.$store.dispatch('web/LoadQuestionData', {
                    resId: this.resId,
                    userResId: this.userResId,
                    toolbox: toolbox,
                });

                // Editor模式下，打开demo code mask
                this.$store.commit('web/SetIsShowDemoCode', true);
            },

            selectSprite(id) {
                this.$refs.main.selectSprite(id);
            },
            deleteSprite(id) {
                this.$refs.main.deleteSprite(id);
            },
            // 切换舞台
            reloadStage(nav) {
                this.$refs.main.reloadStage(nav);
            },
            handleCmd(data) {
                switch (data.cmd) {
                    case 'save':
                        this.save()
                        break
                    case 'release':
                        this.release()
                        break
                    case 'switchCode':
                        this.switchCode(data.params)
                        break
                    case 'switchNav':
                        this.switchNav(data.params)
                        break
                    case 'copyRes':
                        this.copyRes()
                        break
                    case 'toggleData':
                        this.toggleData()
                        break
                    case 'toggleShowDemoCode':
                        this.toggleShowDemoCode()
                        break
                }
            },
            // // 上传完成后回调：
            // uploadDoneCallback(newCover) {
            //     // 更新数据库，保存新的coverId
            //     saveProjectCover({
            //         id: this.pid,
            //         newCover: newCover
            //     });
            // },
            autoSave() {
                if (this.curEnv == 'classroom' && this.current == 'user') {
                    this.save(false);
                }
            },
            async save(showAlert = true) {
                // console.log(this.curEnv);
                // console.log('auto save ~~~');
                // 保存截屏
                if (this.curEnv == 'ide') {
                    let cover = window.stageInstance.screenshot();
                    // console.log(cover);

                    // 想改成直接在前端保存，没成功
                    // 好像是base64解码的地方有问题

                    // // preg_match('/^(data:\s*image\/(\w+);base64,)/', $cover, $result)
                    // let coverData = cover.replace(/^data:image\/(\w*);base64,/, '');
                    // // console.log(coverData);
                    // let img = window.atob(coverData)
                    // console.log(img)
                    // await qiniu.uploadFile(img, 'covers', this.uploadDoneCallback)

                    await saveProjectCover(qs.stringify({
                        pid: this.pid,
                        cover: cover
                    }));
                }

                // 获取项目数据
                CoreData.saveBlock(this.curActor.id);
                let data = CoreData.getSaveData('user');

                if (this.curEnv == 'classroom') {
                    if (this.userResId == '') {
                        let res = await genResId()
                        this.userResId = res.data.resId

                        saveMySectionRes({ cid: this.cid, sid: this.sid, id: this.userResId })
                    }
                    await cos.putObject('project', 'project', this.userResId, JSON.stringify(data))

                } else if (this.curEnv == 'editor') {
                    if (this.resId == '') {
                        let res = await genResId()
                        this.resId = res.data.resId

                        saveAdminSectionRes({ cid: this.cid, sid: this.sid, resId: this.resId })
                    }
                    data = CoreData.getSaveData('demo');
                    await cos.putObject('project', 'project', this.resId + '.demo', JSON.stringify(data))

                    data = CoreData.getSaveData('user');
                    await cos.putObject('project', 'project', this.resId + '.res', JSON.stringify(data))

                } else if (this.curEnv == 'ide') {
                    // Admin中应该不会进入这个分支
                    if (this.pid != '') {
                        await cos.putObject('project', 'project', this.pid, JSON.stringify(data));

                    } else {
                        let res = await createProject({
                            name: this.projInfo.name,
                            type: 'SCRATCH'
                        })
                        // console.log(res)
                        if (res && res.data) {
                            let pid = res.data.pid

                            this.$store.commit('web/SetProjectId', pid)

                            this.$router.replace({ query: { id: pid } });

                            await cos.putObject('project', 'project', this.pid, JSON.stringify(data));
                        }
                    }

                } else if (this.curEnv == 'question') {
                    if (this.pid != '') {
                        data = CoreData.getSaveData('demo');
                        await cos.putObject('project', 'project', this.resId + '.question', JSON.stringify(data))

                        data = CoreData.getSaveData('user');
                        await cos.putObject('project', 'project', this.resId + '.ans', JSON.stringify(data))
                        this.parseBlocks(data)
                    }
                }
                if (showAlert) {
                    this.$store.dispatch('web/SetAlert', {type: 'success', msg: '保存成功'});
                }
            },
            async parseBlocks(data) {
                // console.log(data.backdrop)
                let arrBlocks = []
                for (const actor of Object.keys(data.actors)) {
                    // console.log(data.actors[actor].block)
                    let content = data.actors[actor].block
                    let results = content.matchAll(/<block type="([\w]+)"/g)
                    for (const result of results) {
                        // console.log(result[1])
                        arrBlocks.push(result[1])
                    }
                }
                arrBlocks = Array.from(new Set(arrBlocks))

                await saveQuestionBlocks({
                    qid: this.pid, 
                    blocks: JSON.stringify(arrBlocks)
                })
            },
            async release() {
                this.save()

                await sendReleaseMsg({
                    type: 'project',
                    category: 'scratch',
                    id: this.curEnv == 'classroom' ? this.userResId : this.pid,
                    cid: ''
                });

                this.$store.dispatch('web/SetAlert', {type: 'success', msg: '发布成功'});
            },
            switchCode(params) {
                // 更新一下代码区内容
                Ide.workspace2code();
                // 切换代码区显示
                this.$store.commit('web/ShowArea', { name: 'CODE_AREA', isShow: params.showCode });
            },
            switchNav(params) {
                if (params.nav != this.current) {
                    CoreData.saveBlock(this.curActor.id);

                    this.$store.commit('web/SetCurrent', params.nav);
                    this.$refs.main.reloadStage(params.nav);

                    if (this.curEnv == 'editor') {
                        this.$store.commit('web/SetIsShowDemoCode', true);
                    }
                }
            },
            copyRes() {
                CoreData.copyRes('demo', 'user');

                this.$store.dispatch('web/SetAlert', {type: 'success', msg: '复制素材成功'});
            },
            toggleData() {
                let isShow = !this.ide.area.isShowDataArea;
                this.$store.commit('web/ShowArea', { name: 'DATA_AREA', isShow: isShow });

                // if (isShow && this.dataAreaData.length == 0) {
                if (isShow) {
                    // 展示初始化的数据区变量
                    this.$store.commit('web/InitDataAreaData');
                }
            },
            toggleShowDemoCode() {
                this.$store.commit('web/ToggleShowDemoCode');
            },
        },
        components: {
            MainArea,
            Bottom,
            Loading,
        }
    }
</script>


<style lang="scss">
// 用于隐藏iframe区中的上下滚动条
html, body {
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.scratch-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .area {
        width: 100%;
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        z-index: 100;
    }

//   // 隐藏积木区横向&纵向滚动条
//   .blocklyScrollbarHandle {
//     opacity: 0;
//   }
}
</style>
