<template>

    <div class="page-container">
        
        <Header @sendCmd="sendCmd" :headerConfig="headerConfig" ></Header>
        <Scratch ref="page" />
        
    </div>

</template>

<script>
import { setHeaderConfig } from '@/utils/common';
import Header from '../header/Header';
import Scratch from '@/views/pages/ide/scratch/Scratch'

export default {
    name: 'ide-scratch',
    data: function() {
        return {
            headerConfig: {}
        }
    },
    created() {
    },
    activated() {
    },
    mounted() {
        // 根据path判断加载哪个Header
        let curEnv = ''

        // 加载页面参数
        let pid = ''
        let sid = ''
        let cid = ''
        if (this.$route.query.pid) {
            pid = this.$route.query.pid
        }
        if (this.$route.query.cid) {
            cid = this.$route.query.cid
        }
        if (this.$route.query.sid) {
            sid = this.$route.query.sid
        }

        // 判断当前环境
        let path = this.$route.path
        if (path == '/iframe/classroom/scratch') {
            curEnv = 'classroom'

            this.headerConfig = setHeaderConfig({
                goBack: true,
                fullscreen: true,
                nav: true,
                demoCode: true,
                data: true,
                save: true,
                code: true,
            })

        } else if (path == '/iframe/editor/scratch') {
            curEnv = 'editor'

            this.headerConfig = setHeaderConfig({
                goBack: true,
                fullscreen: true,
                nav: true,
                copyRes: true,
                data: true,
                save: true,
                code: true,
                changeBg: true,
            })
            
        } else if (path == '/iframe/exercise/scratch') {
            curEnv = 'exercise'

            this.headerConfig = setHeaderConfig({

                goBack: true,
                fullscreen: true,
                data: true,
                code: true,
            })
            
        } else if (path == '/iframe/ide/scratch') {
            curEnv = 'ide'

            this.headerConfig = setHeaderConfig({
                goBack: true,
                projName: true,
                fullscreen: true,
                save: true,
                release: true,
                data: true,
                code: true,
            })
        } else if (path == '/iframe/question/scratch') {
            curEnv = 'question'

            this.headerConfig = setHeaderConfig({
                goBack: true,
                fullscreen: true,
                nav: true,
                data: true,
                save: true,
                code: true,
            })
            
        }

        // 加载数据
        this.$refs.page.loadPage(cid, sid, pid, curEnv)
    },
    methods: {
        sendCmd(data) {
            this.$refs.page.handleCmd(data)
        },
    },
    components: {
        Header,
        Scratch
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #222;
}
</style>
